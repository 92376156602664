import RecordRTC from "recordrtc";

const itemsResultView = require("../templates/item_search.ejs")
const branchCell = require('../templates/branch_search.ejs')

export class AiChat {

  constructor(ui) {
    this.ui = ui;

    this.recorder = undefined
    this.mediaStream = undefined
    this.mediaRecorder = undefined
    this.audioChunks = []
    this.chattingUrl = this.ui.dataset.chattingUrl;
    this.lng = 55.28233039805896
    this.lat = 25.214064297239194
    this.collapseButton = $('#collapse-button')
    this.branchMarkers = []
    O.on('MapView.clicked', (data) => {
      this.lat = data.event.latLng.lat()
      this.lng = data.event.latLng.lng()
      this.location = `${this.lat},${this.lng}`
    });

    O.on('MapView.created', (map) => {
      this.map = map
    });

    this.loadListeners()
  }

  loadListeners() {
    this.recordButtonListener()
    this.sendButtonListener();
  }

  appendMessage(message) {
    var messageHtml = `
      <div class="message sent">
          <div class="message-bubble">${message}</div>
      </div>`;
    $('#chat-messages').append(messageHtml);
    $('#chat-messages').scrollTop($('#chat-messages')[0].scrollHeight);
    $('#message-input').val('');
  }

  sendButtonListener() {
    $('#send-button').click(function () {
      Packs.application.globalFun.showLoadingWheel();
      var messageText = $('#message-input').val().trim();

      if (messageText) {
        this.appendMessage(messageText)

        $.ajax({
          url: this.chattingUrl,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({ message: messageText, city_name: 'dubai' }),
          success: function (response) {
            var responseText = response.message;
            this.appendMessage(responseText);
            Packs.application.globalFun.hideLoadingWheel();
          }.bind(this),
          error: function (jqXHR, textStatus, errorThrown) {
            Packs.application.globalFun.hideLoadingWheel();
          }
        });
      }
    }.bind(this));

    $('#message-input').keypress(function (e) {
      if (e.which === 13) {
        $('#send-button').click();
      }
    });
  }

  recordButtonListener() {

    $('#record-button').click(() => {
      if (!this.mediaRecorder || this.mediaRecorder.state === "inactive") {

        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          this.recorder = RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/m4a',
          });

          this.mediaStream = stream;
          this.recorder.startRecording();

          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.start();
          $('#recording-indicator').show();

          this.mediaRecorder.ondataavailable = event => {
            this.audioChunks.push(event.data);
          };

          $(this).text('Stop Recording');
        }).catch(error => {
          console.error("Error accessing the microphone:", error);
          alert("Could not access the microphone. Please check your permissions.");
        });
      } else {

        this.recorder.stopRecording(() => {
          const audioBlob = this.recorder.getBlob();
          // const audioUrl = URL.createObjectURL(audioBlob);
          this.sendAudio(audioBlob)
          if (this.mediaStream) { this.mediaStream.getTracks().forEach(track => track.stop()); }
        });
        $('#recording-indicator').hide();
        this.mediaRecorder.stop();
        $(this).text('🎤 Record');
      }
    });
  }

  sendAudio(audioBlob) {
    this.branchMarkers.forEach(marker => { marker.marker.setMap(null) })
    const formData = new FormData()
    formData.append('audio', audioBlob, `audio_${Date.now()}_${Math.floor(Math.random() * 10000)}.m4a`)
    formData.append('latitude', this.lat)
    formData.append('longitude', this.lng)
    formData.append('city_name', 'dubai')
    Packs.application.globalFun.showLoadingWheel()

    $.ajax({
      url: this.chattingUrl,
      method: 'POST',
      processData: false,
      contentType: false,
      data: formData,
      success: (response) => {
        if (response.message.route) { this.map.drawPolyLine(response.message.route) }
        this.branchMarkers = []
        $('#itemsResult').empty()
        this.appendMessage(JSON.stringify(response.message))
        response.branches.forEach(branch => {
          var marker = this.map.addBranchMarker(branch)
          this.branchMarkers.push(marker)
          branch.minsAway = Math.ceil(this.calculateDistance(new google.maps.LatLng(parseFloat(this.lat),
            parseFloat(this.lng)), new google.maps.LatLng(parseFloat(branch.latitude), parseFloat(branch.longitude))))

          $('#itemsResult').append(branchCell({ branch: branch }))
        })

        response.items.forEach(item => {
          item.branch.minsAway = Math.ceil(this.calculateDistance(new google.maps.LatLng(parseFloat(this.lat),
            parseFloat(this.lng)), new google.maps.LatLng(parseFloat(item.branch.latitude),
              parseFloat(item.branch.longitude))))
        })

        // $('#items').append(itemsResultView({ response: response }))
        $('#itemsResult').append(itemsResultView({ response: response }))
        // $('#itemsResultModal').modal('show');
        this.collapseButton.trigger('click')
        Packs.application.globalFun.hideLoadingWheel();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Packs.application.globalFun.hideLoadingWheel();
        console.error("Error sending audio:", errorThrown);
      }
    })
  }

  calculateDistance(from_location, to_location) {
    var distanceMeter = google.maps.geometry.spherical.computeDistanceBetween(from_location, to_location);
    var distanceKiloMeter = (distanceMeter / 1000);
    var timeDiff = ((distanceKiloMeter * 60) / 40)
    return (timeDiff < 2) ? 2 : timeDiff
  }
}