module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"mt-2 branch\">\n  <div class=\"row\">\n    <div class=\"col\">\n      <div class=\"d-flex \">\n        <div>\n          <img src=")
    ; __append(escapeFn(branch.logo_url ))
    ; __append(" width=\"50\" class=\"circle\"/>\n        </div>\n        <div class=\"col align-self-center item-info\">\n          <p class=\"p-0 m-0 small-hint\">\n            ")
    ; __append(escapeFn( branch.shop_name ))
    ; __append("\n          </p>\n          <small class=\"p-0 m-0 small-hint\">\n            <i class=\"fa fa-map-marker\" aria-hidden=\"true\"></i>\n            ")
    ; __append(escapeFn( branch.name ))
    ; __append("\n          </small>\n\n          <small class=\"p-0 m-0 small-hint\">\n            <i class=\"fa fa-clock-o\" aria-hidden=\"true\"></i>\n            ")
    ; __append(escapeFn( branch.minsAway ))
    ; __append(" min away\n              ")
    ; __append(escapeFn( branch.name ))
    ; __append("\n          </small>\n        </div>\n        <div>\n          <a href=")
    ; __append(escapeFn(`/moderator/shop/${branch.shop_id}/branches/${branch.id}/menu_items`))
    ; __append(" class=\"btn btn-orange\n            strong-rounded-corners\">View Menu</a>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<hr />")
  }
  return __output;

}